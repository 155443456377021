import { useLockBodyScroll } from 'hooks'
import { createContext, useContext, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { DEFAULT_ASSET_TIMEFRAME, DEFAULT_ORDER } from 'utils/constants'

const TableFiltersContext = createContext({})

export const TableFiltersContextProvider = ({
    params = {},
    setParams = () => {},
    getDefaultSortBy = () => {},
    getDefaultColumns = () => {},
    defaultTimeframe = DEFAULT_ASSET_TIMEFRAME,
    otherDefaultParams = {},
    categories = [],
    sortByChoices = [],
    children,
}) => {
    const [openFilter, setOpenFilter] = useState(null)

    const isTablet = useMediaPredicate('(min-width: 768px)')
    const showOverlay = useMediaPredicate('(max-width: 1119px)')

    useLockBodyScroll(showOverlay && openFilter !== null)

    return (
        <TableFiltersContext.Provider
            value={{
                params,
                setParams,
                resetParams: newCategory => {
                    const newSortBy = getDefaultSortBy(newCategory?.key)
                    const newTimeframe = newSortBy ? (params?.timeframe ?? defaultTimeframe) : null
                    const newOrder = newSortBy ? (params?.order ?? DEFAULT_ORDER) : null
                    const defaultColumns = getDefaultColumns(newCategory.key) ?? []
                    const newColumns = isTablet ? defaultColumns : defaultColumns.slice(0, 2)

                    setParams({
                        ...params,
                        category: newCategory,
                        sortBy: newSortBy,
                        timeframe: newTimeframe,
                        order: newOrder,
                        byChange: false,
                        columns: newColumns,
                        ...otherDefaultParams,
                    })
                },
                categories,
                sortByChoices,
                openFilter,
                setOpenFilter,
            }}
        >
            {children}
        </TableFiltersContext.Provider>
    )
}

export const useTableFiltersContext = () => {
    return useContext(TableFiltersContext)
}
