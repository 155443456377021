import classNames from 'classnames'
import { ToggleButton } from 'components/ui/buttons/toggleButton'
import { ExpandableVerifiedTag } from 'components/ui/expandableVerifiedTag'
import { Loader } from 'components/ui/loader'
import { TableView } from 'components/ui/tableViewSelector'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { useTableFiltersContext } from 'contexts/tableFiltersContext'
import { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { tableState, useTheme } from 'state'
import { LIQUID_STAKING_PROVIDER_KEY, OPERATOR_KEY, ORDER_CHOICES, PageSizes } from 'utils/constants'

import { ExpandableProTag } from 'components/ui/expandableProTag'
import { withTooltip } from 'components/ui/tooltip'
import { GTMEvent, logEvent } from 'utils/GTM'
import styles from './table.module.scss'

export const NoDataStatus = ({ isLoading = false }) => {
    return (
        <div className={styles.statusWrap}>
            {isLoading ? <Loader centered /> : <p>There are no results for your search. Please revise your filters.</p>}
        </div>
    )
}

export const Card = ({
    noExpansion = false,
    withExpansion = true,
    withVerifiedTag = false,
    renderMetricsCard = () => {},
    renderExpandedCard = () => {},
    asset,
    isPro = false,
}) => {
    const isTableExpandedValue = tableState.isTableExpanded.value
    const [isExpanded, setIsExpanded] = useState(isTableExpandedValue)
    useEffect(() => {
        setIsExpanded(isTableExpandedValue)
    }, [isTableExpandedValue])

    const theme = useTheme()

    return (
        <div className={styles.card} data-theme={theme}>
            <div className={styles.mainRow}>
                {withVerifiedTag && (
                    <div className={styles.verifiedTag}>{isPro ? <ExpandableProTag /> : <ExpandableVerifiedTag />}</div>
                )}
                <div className={styles.basis}>
                    {renderMetricsCard()}
                    {withExpansion && !noExpansion && (
                        <div
                            className={classNames(styles.more, {
                                [styles.expanded]: isExpanded,
                            })}
                        >
                            {renderExpandedCard()}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.expandBtnWrap, {
                    [styles.hidden]: !withExpansion && !noExpansion,
                })}
            >
                <div
                    className={styles.expandBtn}
                    onClick={() => {
                        setIsExpanded(!isExpanded)
                        if (asset) {
                            logEvent(GTMEvent.ExpandTableClick, {
                                asset: asset?.slug,
                            })
                        }
                    }}
                >
                    <ToggleButton expanded={isExpanded} />
                </div>
            </div>
        </div>
    )
}

export const Row = ({
    isExpanded = false,
    withExpansion = true,
    withVerifiedTag = false,
    renderMetricsRow = () => {},
    renderExpandedRow = () => {},
    isPro = false,
}) => {
    return (
        <div
            className={classNames(
                styles.row,
                'bg-[#ffffff80] hover:bg-[#ffffff] dark:bg-contrast-1 dark:hover:bg-contrast-2'
            )}
        >
            <div
                className={classNames(styles.mainRow, {
                    [styles.withExpansion]: withExpansion,
                })}
            >
                {withVerifiedTag && (
                    <div className={styles.verifiedTag}>{isPro ? <ExpandableProTag /> : <ExpandableVerifiedTag />}</div>
                )}
                <div className={styles.basis}>
                    {renderMetricsRow()}
                    {withExpansion && (
                        <div
                            className={classNames(styles.more, {
                                [styles.expanded]: isExpanded,
                            })}
                        >
                            {renderExpandedRow()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const TableHeader = ({ leftHeaders = [], rightHeaders = [] }) => {
    const { params, setParams, sortByChoices } = useTableFiltersContext()

    const isTablet = useMediaPredicate('(min-width: 768px)')

    const selectedSortOption = params?.sortBy
    const selectedSortOrder = params?.order
    const selectedSortByChange = params?.byChange
    const selectedTimeframe = params?.timeframe
    const selectedSortOrderOption = ORDER_CHOICES.find(option => option.key === selectedSortOrder?.key)

    const ascOrderOption = ORDER_CHOICES.find(option => option.key === 'asc')
    const descOrderOption = ORDER_CHOICES.find(option => option.key === 'desc')

    return (
        <Row
            withExpansion={false}
            isExpanded={false}
            renderMetricsRow={() => (
                <div className={classNames(styles.tableHeader, '!px-4 !py-3')}>
                    <div className='flex items-center gap-2'>
                        {leftHeaders.map(header => (
                            <div
                                key={`${header.key}-${header.label}`}
                                className={classNames('flex items-center', header.width ? `w-[${header.width}]` : '')}
                            >
                                <span className='text-xs text-contrast-3'>{header.label}</span>
                            </div>
                        ))}
                    </div>
                    <div className='flex items-center justify-end gap-5'>
                        {rightHeaders.map(header => {
                            const isSortable = sortByChoices?.some(option => option?.key === header.key)

                            const isSelected = selectedSortOption?.key === header.key && selectedSortByChange === false
                            const isSelectedByChange =
                                selectedSortOption?.key === header.key && selectedSortByChange === true

                            const isAsc = selectedSortOrderOption?.key === ascOrderOption?.key
                            const isAlignLeft = header?.align === 'left'
                            const isWithPercentage = header?.withPercentage
                            const isWithTooltip = Boolean(header?.tooltipTextObj)

                            const parsedWidth = header.width ? parseInt(header.width, 10) : 0
                            const headerWidth = isTablet ? header.width : header.width ? `${parsedWidth - 50}px` : ''

                            const LabelWithTooltip = withTooltip(() => {
                                return (
                                    <span
                                        className={classNames(
                                            'text-xs',
                                            isSelected ? 'text-primary' : 'text-contrast-3',
                                            isSortable && !isSelected ? 'hover:text-contrast-5' : '',
                                            isAlignLeft ? 'text-left' : 'text-right',
                                            isWithTooltip ? 'underline decoration-dotted' : ''
                                        )}
                                    >
                                        {header.label}
                                    </span>
                                )
                            })

                            return (
                                <div
                                    key={`${header.key}-${header.label}`}
                                    className={classNames(
                                        'flex items-center gap-2',
                                        headerWidth ? `w-[${headerWidth}]` : '',
                                        isAlignLeft ? 'justify-start' : 'justify-end'
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            'flex items-center',
                                            isSortable ? 'cursor-pointer' : '',
                                            isAlignLeft ? 'justify-start' : 'justify-end'
                                        )}
                                        onClick={() => {
                                            if (isSortable) {
                                                setParams({
                                                    ...params,
                                                    sortBy: sortByChoices.find(option => option.key === header.key),
                                                    order: !isSelected || isAsc ? descOrderOption : ascOrderOption,
                                                    byChange: false,
                                                })
                                            }
                                        }}
                                    >
                                        <div className='flex gap-2 items-center'>
                                            {isSelected ? (
                                                <span
                                                    className={classNames(
                                                        'icon [--size:10px] [--color:--c-primary]',
                                                        isAsc ? 'icon-angle-sort-asc' : 'icon-angle-sort-desc'
                                                    )}
                                                />
                                            ) : null}
                                            <LabelWithTooltip
                                                tooltipObject={header?.tooltipTextObj ?? { title: '', text: '' }}
                                            />
                                        </div>
                                    </div>
                                    {isWithPercentage && isTablet ? (
                                        <div
                                            className={classNames(
                                                'flex items-center',
                                                'w-[50px]',
                                                isSortable ? 'cursor-pointer' : '',
                                                isAlignLeft ? 'justify-start' : 'justify-end'
                                            )}
                                            onClick={() => {
                                                if (isSortable) {
                                                    setParams({
                                                        ...params,
                                                        sortBy: sortByChoices.find(option => option.key === header.key),
                                                        order:
                                                            !isSelectedByChange || isAsc
                                                                ? descOrderOption
                                                                : ascOrderOption,
                                                        byChange: true,
                                                    })
                                                }
                                            }}
                                        >
                                            <div className='flex gap-2 items-center'>
                                                {isSelectedByChange ? (
                                                    <span
                                                        className={classNames(
                                                            'icon [--size:10px] [--color:--c-primary]',
                                                            isAsc ? 'icon-angle-sort-asc' : 'icon-angle-sort-desc'
                                                        )}
                                                    />
                                                ) : null}
                                                <span
                                                    className={classNames(
                                                        'text-xs',
                                                        isSelectedByChange ? 'text-primary' : 'text-contrast-3',
                                                        isSortable && !isSelectedByChange ? 'hover:text-contrast-5' : ''
                                                    )}
                                                >
                                                    {selectedTimeframe?.name}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        />
    )
}

export const Table = ({
    data = null,
    isLoading = false,
    noDataFound = false,
    isEnd = false,
    getItemsFromData = () => {},
    renderItemComponent = () => {},
    profileItem = null,
    isShowHeader = false,
    renderHeaderComponent = () => {},
}) => {
    const { selectedTableView, paginationParams, setHasMore, queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key

    const pageSizeNumber = Number(paginationParams?.pageSize?.key ?? PageSizes.Twenty)

    useEffect(() => {
        setHasMore(!isEnd)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnd])

    const isLoadingData = !data && isLoading

    let rowItems = []
    if (isLoadingData) {
        rowItems = [...Array(pageSizeNumber).keys()]
    } else if (data?.length) {
        rowItems = getItemsFromData(data)
    }

    const hasOperatorList =
        !isLoadingData && profileItem?.type?.key === LIQUID_STAKING_PROVIDER_KEY && categoryKey !== OPERATOR_KEY

    const allItems = [...rowItems]

    // Map operators to their parent reward option and show in expanded view, if there is no parent, then show in main list
    if (hasOperatorList) {
        rowItems = rowItems.filter(item => item?.type?.key !== OPERATOR_KEY)
        rowItems = [
            ...rowItems,
            ...allItems.filter(
                item =>
                    item?.type?.key === OPERATOR_KEY &&
                    // search for parent
                    !allItems.some(i => {
                        return i?.inputAssets?.[0]?.slug === item?.inputAssets?.[0]?.slug && i !== item
                    })
            ),
        ]
    }

    if (!isLoading && noDataFound) {
        return (
            <div className={styles.dataStatus}>
                <p>There are no results for your search. Please revise your filters.</p>
            </div>
        )
    }

    return (
        <div className='overflow-x-auto md:overflow-x-visible'>
            <div className={selectedTableView === TableView.Grid ? styles.grid : styles.table}>
                {isShowHeader ? <div className={styles.tableRow}>{renderHeaderComponent()}</div> : null}
                {rowItems?.map((item, idx) => {
                    const operators =
                        hasOperatorList &&
                        allItems.filter(i => {
                            return i?.inputAssets?.[0]?.slug === item?.inputAssets?.[0]?.slug && i !== item
                        })
                    return (
                        <div key={`item-${item?.id ?? ''}-${idx} + 1}`} className={styles.tableRow}>
                            {renderItemComponent(selectedTableView === TableView.Grid, {
                                index: idx + 1,
                                item: isLoadingData ? null : item,
                                isLoading: isLoadingData,
                                operators,
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
